/* Global styles for chatbot Component */

/* Implemented by Akash kumar S */

.navbar-collapse {
  flex-grow: 0 !important;
  justify-content: end;
}
.rsc {
  position: absolute !important;
  bottom: 0px !important;
  right: 0px !important;
}

.navbar {
  position: sticky !important;
  top: 0 !important;
  z-index: 999 !important;
}

nav {
  padding-top: 0rem !important;
  padding-bottom: 0px !important;
}
/* th{
  padding-left: 3rem !important;
} */
.nav-link {
  font-size: 14px !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 23px !important;
  padding-right: 23px !important;
  color: #354f81 !important;

  position: relative !important;
  opacity: 1 !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 650 !important;
}

.yzpTb {
  max-width: 100% !important;
}

.ChatBotElements {
  position: sticky;
  bottom: 0;
}
.me-auto :hover {
  background-color: #e40046 !important;

  color: #fff !important;
  /* padding: 0px !important; */
}

.nav-link :hover {
  background-color: #e40046 !important;
}
.chatComponent {
  display: none;
}

a {
  text-decoration: none !important;
  color: black !important;
}

.AddressContainer {
  text-align: center;
}
.AddressContainer p {
  margin: 0;
  color: #fff;
  font-size: clamp(12px, 2.5vw, 15px);
  line-height: 1.5;
  letter-spacing: 10;
}
.changeAddress {
  text-align: left;
}
.changeAddress tr {
  line-height: 1.6;
}
@media (max-width: 1000px) {
  .me-auto {
    align-items: center !important;
    padding-top: 4rem;
  }

  .me-auto :hover {
    background-color: #e40046 !important;
    padding: 10px !important;

    color: #fff !important;
    padding: 0px !important;
    border-radius: 25px !important;
  }
  .nav-link {
    padding-top: 0rem !important;
  }
}

@media (max-width: 568px) {
  .fZLbxv {
    height: 40% !important;
    width: 50% !important;
  }
  .fAsOzF {
    max-width: 70% !important;
  }
  th {
    padding-left: 0rem !important;
  }
}
@media (max-width: 300px) {
  .fZLbxv {
    height: 40% !important;
    width: 67% !important;
  }
}

.sc-jSUZER .sc-eDvSVe {
  text-align: center;
}
.sc-jSUZER .sc-eDvSVe .sc-dkrFOg {
  margin-bottom: 1rem;
}
/* .iIPZUo,
.cIPcqL {
  max-width: 80% !important;
  font-size: clamp(8px, 3vw, 16px) !important;
} */
.tryAgainContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.pleaseTryAgain {
  background-color: #fff;
  text-align: center;
  padding: 0.3rem;
  border-radius: 5px;
  border: none;
}

/* monthPicker height */

.custom-input {
  border-radius: 15px;
  border: 1px #0c8af8 solid;
  padding: 4px 12px;
  background-color: white;
  height: 38px;
}

.custom-input:focus {
  outline: none;
  border: 1px solid #0c8af8;
  box-shadow: 0 0 5px 1px #0074d9;
}

/* .rmdp-input {
  height: 38px !important;
} */
.subnewDetails tr {
  line-height: 2;
}
.hRhLEU {
  bottom: 5px !important;
}

@media (max-width: 567px) {
  .elRJyD {
    height: 75vh !important;
    width: 90% !important;
  }
}
