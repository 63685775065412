
/* Styles for Chatbot Footer Component */


.Footer{
    background-color: #e6e6e6;
    
      
  }
  

  .FooterFlexContainer{
    display: flex;
    justify-content: space-evenly;
   
    position: relative;
  }
  .SubFooter{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 10px 0px
   
  }
  /* .ChatBotContainer{

  display:flex;
  justify-Content:end;
  position:sticky;
  bottom: 0;
  } */
  .QuickLinkContainer{
    padding: 2rem 0rem 0rem 0rem;
    
  }
  .QuickLinkHeading{
    font-size: clamp(1.2rem, 1.2vw, 1.5rem);
    margin-bottom: 10px;
    margin-top: 0;
    line-height: 1.5;
    font-family: "Heebo", sans-serif;
    color: #0e0e3c;
    font-weight: 700;
  }
  

  .GetinTouchwithUs{

    padding: 2rem 5rem;
    gap: 4%;
    background-color: #ffffff;
    top: -50px;
    position: relative;
    display: flex;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        align-items: end;
    justify-content: center;
    margin-right: 8rem;
    margin-left: 8rem;
  
  }

  .GetInTouchHeading h1{

    color: #0e0e3c;
    font-family: "Heebo", sans-serif;
    font-size: clamp(1.3rem, 1.7vw, 3rem);
    font-weight: 700;

  }
  .LogoContainer{
    padding-top: 3rem;
  }



  .ClickHereButton{

    background-color: brown !important;
    border-radius: 10px !important;
    border: none !important;
    color: white !important;
    padding: 12px !important;
  }
  .ContactContainer{
    padding-top: 3rem;
  }

  .ContactContainer h1{
  
    font-size: clamp(1.2rem,1.2vw,1.5rem);
    margin-bottom: 10px;
    margin-top: 0;
    line-height: 1.5;
    font-family: heebo,sans-serif;
    color: #0e0e3c;
    font-weight: 600;

  }

  .SubFooterHeading p{
    font-family: roboto,sans-serif;
    opacity: 0.8;
  }
  
  @media(max-width:1000px){

    .FooterFlexContainer{
        flex-direction: column;
          justify-content: center;
          align-items: center;
        }
    
        .QuickLinkHeading{
          text-align: center;
        }
        .QuickLinkContents{
          text-align: center;
        }
        .ContachHeading{
          text-align: center;
        }
        .ContactContents{
          text-align: center;
        }
        .LogoContainer{
          text-align: center;
        }

        .GetinTouchwithUs{

          padding: 2rem 0rem;
        flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 5rem;
          margin-left: 5rem;
        
        }

        .GetInTouchHeading h1{

        
         
          font-size: clamp(1rem, 1.7vw, 3rem);
          font-weight: 500;
          text-align: center;
      
        }
      
        .LogoContainer{
          padding-top: 0rem;
        }
        .ContactContainer{
        text-align: center;
        padding-top: 0rem;
        }
        .SubFooterHeading p{
          text-align: center;
        }
      }
      @media(max-width:500px){

        .GetinTouchwithUs{

          padding: 2rem 0rem;
        flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 2rem;
          margin-left: 2rem;
        
        }

      }
  